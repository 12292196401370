import { useState } from "react";
import axios from "axios";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// logo
import LogoImg from "./image/logoimage.png";

const baseURL = process.env.REACT_APP_API;

function App() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [massege, setMassege] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const result = re.test(email);

      if (!result) {
        return setMassege("*** Your mail no valid");
      }

      const response = await axios.post(
        `${baseURL}/api/planethours/subscribe`,
        {
          email,
        }
      );
      const data = response.data;
      if (data) {
        setMassege("*** Thank for Subscribe");
        setEmail("");
      } else {
        setMassege("*** Try again");
        setEmail("");
      }
    } catch (error) {
      console.log(error.massege);
      setEmail("");
    } finally {
      setLoading(false);
      setEmail("");
    }
  };

  return (
    <div className="container mt-5 pt-5 logo">
      <div className=" d-flex align-items-center  mt-5 ">
        <div>
          <img className="img-fluid" src={LogoImg} alt="" />
          <p className="home-header text-nowrap">
            What is <span> this </span> this !!
          </p>
          <p className="home-header-two text-end text-nowrap">
            that a question!!
          </p>
          <div className="mt-5 pt-5 input">
            <p className="fw-bold">For update Subscribe now-</p>
            <form onSubmit={loading ? null : submitHandler}>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="mt-2 text-end fw-bold text-primary">
                {loading ? "" : massege}
              </p>
              <div className="text-end mt-2">
                <button
                  type="submit"
                  className="btn btn-light shadow-sm border border-primary"
                >
                  {loading ? "Loading..." : "Subscribe"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
